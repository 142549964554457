var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_warehouse_transfer") } },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-form",
                {
                  attrs: {
                    layout: "horizontal",
                    "label-align": "left",
                    form: _vm.form,
                    "label-col": { sm: { span: 24 }, lg: { span: 5 } },
                    "wrapper-col": { sm: { span: 24 }, lg: { span: 19 } }
                  }
                },
                [
                  _c("a-form-item", [
                    _c("h4", { staticClass: "ant-form-text mb-0" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isSubmitted
                              ? _vm.$t("lbl_receive_item")
                              : _vm.$t("lbl_transfer_item")
                          ) +
                          " "
                      )
                    ])
                  ]),
                  _c(
                    "a-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.isSubmitted || _vm.isReceived || _vm.isDraft,
                          expression: "isSubmitted || isReceived || isDraft"
                        }
                      ],
                      attrs: { label: _vm.$t(_vm.formRules.no.label) }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.no.decorator,
                            expression: "formRules.no.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.no.name,
                          placeholder: _vm.$t(_vm.formRules.no.placeholder),
                          autocomplete: "off",
                          disabled: ""
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.from.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.from.decorator,
                              expression: "formRules.from.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.from.name,
                            placeholder: _vm.$t(_vm.formRules.from.placeholder),
                            showSearch: true,
                            "option-filter-prop": "children",
                            "filter-option": false,
                            loading: _vm.loadingLocation,
                            allowClear: true,
                            disabled:
                              _vm.isCancelled ||
                              _vm.isReceived ||
                              _vm.isSubmitted
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListLocation(value)
                            },
                            change: _vm.handleChangeLocationFrom
                          }
                        },
                        _vm._l(_vm.optDataListLocation, function(data) {
                          return _c(
                            "a-select-option",
                            {
                              key: data.id,
                              attrs: {
                                value: data.id,
                                disabled: _vm.disableOpt(data.id, "to")
                              }
                            },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s("" + data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s("" + data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.to.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.to.decorator,
                              expression: "formRules.to.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.to.name,
                            placeholder: _vm.$t(_vm.formRules.to.placeholder),
                            showSearch: true,
                            "option-filter-prop": "children",
                            "filter-option": false,
                            loading: _vm.loadingLocation,
                            allowClear: true,
                            disabled:
                              _vm.isCancelled ||
                              _vm.isReceived ||
                              _vm.isSubmitted
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListLocation(value)
                            }
                          }
                        },
                        _vm._l(_vm.optDataListLocation, function(data) {
                          return _c(
                            "a-select-option",
                            {
                              key: data.id,
                              attrs: {
                                value: data.id,
                                disabled: _vm.disableOpt(data.id, "from")
                              }
                            },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s("" + data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s("" + data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.date.label) } },
                    [
                      _c("a-date-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.date.decorator,
                            expression: "formRules.date.decorator"
                          }
                        ],
                        staticClass: "w-100",
                        attrs: { format: _vm.DEFAULT_DATE_FORMAT, disabled: "" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isSubmitted,
                          expression: "isSubmitted"
                        }
                      ],
                      attrs: { label: _vm.$t(_vm.formRules.receiveDate.label) }
                    },
                    [
                      _c("a-date-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.receiveDate.decorator,
                            expression: "formRules.receiveDate.decorator"
                          }
                        ],
                        staticClass: "w-100",
                        attrs: {
                          "disabled-date": _vm.disabledDate,
                          format: _vm.DEFAULT_DATE_FORMAT,
                          disabled: _vm.isCancelled || _vm.isReceived
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.note.label) } },
                    [
                      _c("a-textarea", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.note.decorator,
                            expression: "formRules.note.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.note.name,
                          autocomplete: "off",
                          placeholder: _vm.$t(_vm.formRules.note.placeholder),
                          disabled: _vm.isCancelled || _vm.isReceived
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.attachment.label),
                        help: _vm.attachmentInfo
                      }
                    },
                    [
                      !!_vm.urlAttachment
                        ? [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "" + _vm.urlAttachment,
                                  rel: "noopener noreferrer",
                                  target: "_blank"
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.urlAttachment || "-") + " "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              [
                                _c("a-button", {
                                  attrs: {
                                    icon: "delete",
                                    loading: _vm.loading.attachment,
                                    type: "danger"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteAttachment(
                                        _vm.urlAttachment
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        : _c(
                            "a-upload",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.attachment.decorator,
                                  expression: "formRules.attachment.decorator"
                                }
                              ],
                              attrs: {
                                action: _vm.Api.UploadLogistic,
                                headers: _vm.headers,
                                name: _vm.formRules.attachment.name,
                                "before-upload": _vm.beforeUpload,
                                accept: ".pdf, .png, .jpg, .xlsx"
                              },
                              on: { change: _vm.handleChangeAttachment }
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    icon: "upload",
                                    loading: _vm.loading.attachment,
                                    disabled:
                                      _vm.isCancelled ||
                                      _vm.isReceived ||
                                      _vm.isSubmitted
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          _vm.formRules.attachment.placeholder
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                    ],
                    2
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t("lbl_status") } },
                    [
                      _c(
                        "label",
                        { staticClass: "ant-form-text", attrs: { for: "" } },
                        [_vm._v(_vm._s(_vm.detailTransfer.state))]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-tabs",
                { attrs: { type: "card" } },
                [
                  _c(
                    "a-tab-pane",
                    {
                      key: "1",
                      staticClass: "bordertabs p-2",
                      attrs: { tab: _vm.$t("lbl_details") }
                    },
                    [
                      _c(
                        "a-space",
                        {
                          staticClass: "w-100",
                          attrs: { direction: "vertical" }
                        },
                        [
                          _c(
                            "a-space",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isDraft || _vm.isNew,
                                  expression: "isDraft || isNew"
                                }
                              ]
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "danger" },
                                  on: { click: _vm.showDeleteConfirmation }
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_delete_row")))]
                              ),
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.addRow }
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_add_row")))]
                              )
                            ],
                            1
                          ),
                          _c("TableCustom", {
                            attrs: {
                              idtable: "table1",
                              onSelectChange:
                                _vm.isNew || _vm.isDraft
                                  ? _vm.onRowSelect
                                  : null,
                              selectedRowKeys:
                                _vm.isNew || _vm.isDraft
                                  ? _vm.selectedRowKeys
                                  : null,
                              dataSource: _vm.dataList,
                              columns: _vm.columnsTable,
                              scroll: { x: "calc(700px + 50%)", y: 400 },
                              paginationcustom: false,
                              defaultPagination: true,
                              handleInput: _vm.handleInput,
                              handleSelect: _vm.onSelectChange,
                              handleSearchSelectTable:
                                _vm.handleSearchSelectTable
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c("a-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "text-right" },
              [
                _c(
                  "a-space",
                  [
                    _c("a-button", { on: { click: _vm.backForm } }, [
                      _vm._v(_vm._s(_vm.$t("lbl_back")))
                    ]),
                    _c(
                      "a-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isReceived || _vm.isSubmitted,
                            expression: "isReceived || isSubmitted"
                          }
                        ],
                        attrs: { type: "primary", loading: _vm.loading.print },
                        on: { click: _vm.handlePrint }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                    ),
                    _vm.documentId &&
                    !_vm.isCancelled &&
                    _vm.$can("update", "warehouse-transfer")
                      ? _c(
                          "a-button",
                          {
                            attrs: {
                              disabled: _vm.isReceived || _vm.isDraft,
                              type: "danger"
                            },
                            on: { click: _vm.cancelForm }
                          },
                          [_vm._v(_vm._s(_vm.$t("lbl_cancel")))]
                        )
                      : _vm._e(),
                    !_vm.documentId
                      ? _c(
                          "a-button",
                          {
                            attrs: { type: "danger" },
                            on: { click: _vm.resetForm }
                          },
                          [_vm._v(_vm._s(_vm.$t("lbl_reset")))]
                        )
                      : _vm._e(),
                    _c(
                      "a-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.isCancelled &&
                              (_vm.$can("create", "warehouse-transfer") ||
                                _vm.$can("update", "warehouse-transfer")),
                            expression:
                              "\n              !isCancelled &&\n              ($can('create', 'warehouse-transfer') ||\n                $can('update', 'warehouse-transfer'))\n            "
                          }
                        ],
                        attrs: {
                          disabled: _vm.isReceived || _vm.isSubmitted,
                          loading: _vm.loading.draft
                        },
                        on: { click: _vm.saveAsDraft }
                      },
                      [_vm._v(_vm._s(_vm.$t("lbl_save_as_draft")))]
                    ),
                    _c(
                      "a-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.isCancelled &&
                              (_vm.$can("update", "warehouse-transfer") ||
                                _vm.$can("create", "warehouse-transfer")),
                            expression:
                              "\n              !isCancelled &&\n              ($can('update', 'warehouse-transfer') ||\n                $can('create', 'warehouse-transfer'))\n            "
                          }
                        ],
                        attrs: {
                          disabled: _vm.isReceived,
                          loading: _vm.loading.submit,
                          type: "primary"
                        },
                        on: { click: _vm.submitForm }
                      },
                      [_vm._v(_vm._s(_vm.$t(_vm.submitText)))]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }